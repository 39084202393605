import { ethers } from "ethers";
import React, { useState } from 'react'
import { Link } from "react-router-dom";
import './connect.css'
import { useToast, immediateToast } from 'izitoast-react'

const Connect = () => {
    
    const [switchToggled, setSwitchToggled] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
 
    const ToggleSwitch = ()=>{

         switchToggled ? setSwitchToggled(false) : setSwitchToggled(true);
    }
     
        async function connect() {
            if (window.ethereum) {
               if (switchToggled === false) {
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
                // Prompt user for account connections
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                console.log("Account:", await signer.getAddress());
                setWalletAddress(await signer.getAddress());
                ToggleSwitch(); 
                immediateToast('success', {
                    message: 'Connected as:' +await signer.getAddress(),
                    color: '#494953',
                    messageColor: '#fff',
                    iconColor: '#fff',
                  })          
                
                }  else{
                    setWalletAddress("");
                    immediateToast('info', {
                        message: "Disconnected",
                        color: '#494953',
                        messageColor: '#fff',
                        iconColor: '#fff',
                      })          
                ToggleSwitch(); 
                
                }
            } else {
                immediateToast('error', {
                    message: 'No Matamask Detected',
                    color: '#494953',
                    messageColor: '#fff',
                    iconColor: '#fff',
                  })
            }
          }
     
        return (
<div style={{ backgroundImage: "url('/assets/images/Viola_v1_00005.gif')" }}  class="main">
        <nav>
        <Link to='/'>
                <img src="assets/images/VIOLA 1.png" alt="NFT-Marketplace" class="logo"></img>
            </Link>
        </nav>
        <div class="content">
            <h1>Viola NFT.</h1>
            <h2>Welcome to the Public Sale!</h2>
            <button  onClick={connect} class="btn">{switchToggled ? 'Disconnect Wallet' : 'Connect wallet'}</button>
            <h3 style={{marginTop:20}}>{walletAddress}</h3>
        </div>
    </div>
        );
    }


export default Connect;