import React from "react";
import { Link } from "react-router-dom";

class Nav extends React.Component {
    render() {
        return (
<div>
{/* navigation menu starts from here */}
    <nav class="navbar" id="navbar">
        <div class="nav-left logo-img">
            <img src="assets/images/VIOLA 1.png" alt="Logo" class="logo"></img>
        </div>
        <div class="menu">
            <i onClick={window['alertHello']} class="fa fa-bars"></i>
        </div>
        <div class="nav-right">
            <div class="nav-links">
                <a href="#" class="nav-link">Home</a>
                <a href="#roadmap" class="nav-link">RoadMap</a>
             <Link to='/connect'>    <a class="btn" onclick="connectWallet()">Connect Wallet</a>   </Link>
            </div>
        </div>
    </nav>
    {/* navigation menu ends here */}

    {/* sidebar social media links  */}
    <div class="sidebar-social">
        <a href="https://discord.gg/dNs8NXrhej"><i class="fab fa-discord"></i></a>
        <i class="fab fa-reddit"></i>
        <a href="https://twitter.com/ViolaNFT_"> <i class="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com/violanft_/"> <i class="fab fa-instagram"></i></a>
    </div>
    {/* sidebar social media links ends here */}

</div>
        );
    }
}

export default Nav;