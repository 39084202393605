import logo from './logo.svg';
import Home from './pages/home'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link} from 'react-router-dom'
import Footer from './pages/footer'
import Nav from './pages/nav'
import About from './pages/about'
import Connect from './pages/connect/connect'
import Welcome from './pages/welcome'
import RoadMap from './pages/roadmap'
import { ethers } from "ethers";
import './App.css';

function App() {
  return (
    <Router>

    <Switch>
      <Route path='/' exact>
      <Nav/>
      <Home/>
      <Welcome/>
      <About/>
      <RoadMap/>
      <Footer/>
      </Route>
      <Route path='/connect' exact>
      <Connect/>
      </Route>
    </Switch>
      </Router>
     
  );
}

export default App;
