import React from "react";

class Footer extends React.Component {
    render() {
        return (
<div>
{/* footer section starts from here  */}
    <div class="footer" style={{ backgroundImage: "url('/assets/images/footer.jpg')" }} id="footer">
        <h2 class="section-heading">
            Join our high net worth group and exclusive events
        </h2>
        <div class="social-items">
            <a href="https://discord.gg/dNs8NXrhej"> <i class="fab fa-discord"></i></a>
            <i class="fas fa-ship"></i>
            <a href="https://twitter.com/ViolaNFT_"> <i class="fab fa-twitter-square"></i></a>
            <a href="https://www.instagram.com/violanft_/"><i class="fab fa-instagram"></i></a>
        </div>
        <div class="copyright">
            <p>All rights Reserved &copy;2022</p>
        </div>
    </div>
</div>
        );
    }
}

export default Footer;