import React from "react";

class Welcome extends React.Component {
    render() {
        return (
<div>  
     {/* welcome section starts from here */}
    <div class="welcome" id="welcome">
        <h1 class="section-heading">
            Welcome to Viola NFT.
        </h1>
        <div class="welcome-dta">
            <div class="left">
                <img src="assets/images/Screen Shot 2022-03-30 at 11.20.49 AM.png" alt=""></img>
                <img src="assets/images/Screen Shot 2022-03-30 at 11.20.49 AM.png" alt=""></img>
                <img src="assets/images/Screen Shot 2022-03-30 at 11.21.49 AM.png" alt=""></img>
                <img src="assets/images/Screen Shot 2022-03-30 at 11.21.49 AM.png" alt=""></img>
            </div>
            <div class="right">
                <p>
                    Since the 2020 NFT boom, this blockchain niche has been driven by a few common trends, including collectibles, memorabilia, utility NFTs, blockchain gaming NFTs, and more. However, one more such significant trend has been the usage of NFTs as an access
                    pass to VIP and Viola clubs. </p>
                <p>
                    There are numerous examples and renditions of this trend, such as VeeFriends and the very prominent Bored Apes Yacht Club. VeeFriends leveraged NFTs as membership access passes to a mega gathering where all the GaryVee fans and NFT holders could meet.
                    On the other hand, the BAYC NFTs have bought celebrities such as Jimmy Fallon and Steph Curry, among others, to be a part of an Viola club that also meets at gatherings both in the metaverse and in the real world.
                </p>
            </div>
        </div>
    </div>
    </div>
        )
    }
}

export default Welcome;