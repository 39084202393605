import React from "react";

class RoadMap extends React.Component {
    render() {
        return (
<div>
{/* navigation menu starts from here */}
<div class='roadmap' id='roadmap'>
        <h1 class='section-heading'>
            GROWTH ROADMAP
        </h1>
        <div class='roadmap-data'>
            <div class='roadmap-plan'>
                <div class='left'>
                    1%
                </div>
                <div class='right'>
                    <h2 class='plan-name'> A Higher Society</h2>
                    <p class='plan-detail'>The first stage of our progression will involve gathering a community that believes in our mission. We want to merge the worlds of cannabis and crypto, and create a more educated view of the subject. So, if you’re an advocate for weed
                        on web3, we’d love to have you on board. We have a host of utilities, and we’re sure you’ll love them all.

                    </p>
                </div>
            </div>
            <div class='roadmap-plan'>
                <div class='left'>
                    25%
                </div>
                <div class='right'>
                    <h2 class='plan-name'>NFT Release</h2>
                    <p class='plan-detail'>
                        Our second stage will introduce our NFTs. X Viola NFTs will be minted onto the Ethereum blockchain, and will represent access into our community. Holders will have exclusive access to a Discord server that will aim to bring the community together through
                        IRL meetups, and reward them for their support with airdrops and merch giveaways.
                    </p>
                </div>
            </div>
            <div class='roadmap-plan'>
                <div class='left'>
                    50%
                </div>
                <div class='right'>
                    <h2 class='plan-name'>Coupons</h2>
                    <p class='plan-detail'>One of our first and most impressive utilities is one that has never been done before, and something that defines our brand. We’ll be using the virtual membership of our NFTs to offer physical utility.
                    <br />
                        This starts with our redeemable coupons, of which each holder will receive 12. These coupons will redeem 3.5 grams of cannabis at verified vendors, and can be used every 30 days for a period of 60 months.
                    </p>
                </div>
            </div>
            <div class='roadmap-plan'>
                <div class='left'>
                    75%
                </div>
                <div class='right'>
                    <h2 class='plan-name'>Coins, Clothing, and Kit</h2>
                    <p class='plan-detail'>Stage 4 will bring further physical redeemables, and another burst of utility for our holders. Firstly, we’ll send out physical Viola coins to each holder, which will be personalized with their NFT number. We’ll then do the same with
                        a range of high-quality clothing, with each holder receiving a tee printed with their NFT number. Holders will also receive a Smoker Kit, which will contain a signature Viola ashtray, Focus storage jar and grinder.
                    </p>
                </div>
            </div>

            <div class='roadmap-plan'>
                <div class='left'>
                    100%
                </div>
                <div class='right'>
                    <h2 class="plan-name">Viola Foundation</h2>
                    <p class='plan-detail'>The last stage of our current planned progression is to build a foundation that works towards the legalization of cannabis in areas that have not made this move. We’ll use a portion of mint profits to work towards a more open-minded
                        future, and consult our holders along the way.
                    </p>
                </div>
            </div>
        </div>
    </div>
    </div>
        );
    }
}

export default RoadMap;