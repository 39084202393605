import React from "react";

class Home extends React.Component {
    render() {
        return (
<div>
{/* header section starts from here */}
    <div class="header" id="home">
        <div class="left">
            <h1>Viola NFT </h1>
            <p>Viola brand will pioneer the cannabis presence in the crypto market and meta-verse by seamlessly integrating physical and digita assets.
            </p>
            <a href="#" class="btn">
                <i class="fab fa-discord"></i> Join the Discord
            </a>
        </div>
        <div class="right">
            <img src="assets/images/Viola_v1_00005.gif" alt="cryptopunk"></img>
        </div>
    </div>
    {/* header section ends here */}
</div>
        );
    }
}

export default Home;