import React from "react";

class About extends React.Component {
    render() {
        return (
<div>
{/* about section starts from here  */}
<div class="retire" id="retire">
        <h1 class="section-heading">
            About Viola NFT
        </h1>
        <div class="retire-dta">
            <div class="left">
                <p>
                    Viola NFT Series NFTs will be a series of unique NFTs that will have the look and feel of monkeys. There will only be one of each kind, and each NFT will have a series of traits and accessories that define it.
                </p>
            </div>
            <div class="right">
                <img src="assets/images/Viola_v1_00005.gif" alt=""></img>
            </div>
        </div>
    </div>
</div>
        );
    }
}

export default About;